.container-preview {
  background-color: #fff;
  height: 467px;
  border-radius: 16px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
  margin-bottom: 4px;
  padding: 0 12px;
  width: max-content;
  margin: auto;
}

.container-preview * {
  border-radius: 16px;
}

.container-preview div {
  height: 467px !important;
}

.container-preview div:empty {
  height: 0px !important;
}

.container-preview canvas {
  border-radius: 16px;
}

.header-menu {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
}

.edit-tablet {
  background: #fff;
  box-shadow: 0px 18px 40px -12px rgba(29, 45, 94, 0.16);
  border-radius: 16px;
  padding: 32px;
  height: 100%;
}
